// DEV
const config = {
    // basename: only at build time to set, and don't add '/' at end off BASENAME for breadcrumbs, also don't put only '/' use blank('') instead,
    // like '/berry-material-react/react/default'
    prod: false,
    basename: '',
    defaultPath: '/',
    fontFamily: `'Poppins', sans-serif`,
    borderRadius: 12,
    outlinedFilled: true,
    theme: 'light',
    idleTimeout: 1200000, // 20 minutes in milliseconds
    presetColor: 'evrima', // default, theme1, theme2, theme3, theme4, theme5, theme6, evrima
    // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
    i18n: 'en',
    rtlLayout: false,
    ga: {
        id: 'G-HHL7KN33JV'
    },

    aws: {
        baseUrl : 'https://f3zvhp1i6g.execute-api.ap-southeast-2.amazonaws.com/Prod/',
        xApiKey : 'PUmFHbzfop8JxXJcbwe9n1XMaZhPuCbu8Knr5c3p',
        signalr: 'https://f3zvhp1i6g.execute-api.ap-southeast-2.amazonaws.com/Prod/messagehub'
    },
    // https://docs.aws.amazon.com/cognito/latest/developerguide/amazon-cognito-user-pools-using-tokens-verifying-a-jwt.html
    awsAuth : {
        baseUrl : 'https://mspr5972r6.execute-api.ap-southeast-2.amazonaws.com/Prod',
        //baseUrl: 'https://localhost:7043/api/Auth',
        jwksAddressTemplate : 'https://cognito-idp.{region}.amazonaws.com/{userPoolId}/.well-known/jwks.json',
        jwksAddress : 'https://cognito-idp.ap-southeast-2.amazonaws.com/ap-southeast-2_Z3B83Gjge/.well-known/jwks.json',
        jwks : {keys:[{alg:"RS256",e:"AQAB",kid:"lsf58hk+8PevUDoXPQpd6g29z8a9fyxVLcLjqsO7BWE=",kty:"RSA",n:"2HG3cAysNlGtYtE14NTUllGacQ9wHiEFTpjxU-mK0KEW8ejkCHy0VljhDXUT8DP77cfxtEctcwowYvFH8ux3K9owflokqKiQxiPBSnzvSNOp_oatroRvpLoY2HjIOAq94iiJp-dceqlAIS_BTmm-UG96ckpvIr_gsRXSQn0SjaeGKCxIU1-lKYZP44a5aHHS46pNXGmKk9A0LrRHPHva_Mz5pJYFtUfNHFC0K4FAhW9bQjKKZcDRWfvnaFlKiHEwS8U302gZtgAhVS6JpuRgTK0aNFF7VZ-ncH2haGdlg5RLXAzCS4xiKmJA8oyHLB2Ms6Cyd0b0MZNXVgTordISTQ",use:"sig"},{alg:"RS256",e:"AQAB",kid:"uxlvtI7qVAHXbdiFWiahp/T+Mrjpw7NwsMl8/GAaIRE=",kty:"RSA",n:"muDgtcSTUFV-ZcNmo9dR0CGAcOSHlHSbmj-SMz0LLsPk16wPuA7JYTn1ZmJ8VEOV0r-O4aoBqMyaYVCz6JYPRvuU8Jl20kYi0n1rfeOTSmNsR6tUmFM9rHnE7X9X-b1Az5nWhNuEu0cqMoQZbMyTwpWrQjnJiGopwgBAFmilUu7PFeD4ekhzY-AZLwFfC_qkgsKJkRdqfjq3uua9nS-kLrNgohSwHA1gCBB1eyrIqTnLjIupV_BzafXkDpGr49qTuVJxhSVci7nZHHnfRzpXdAPMwsStt267ROkSwjydhgM0_vlAjz25hJozl1sRw0xHg6tNNgj5MKCfKEZURQlbhw",use:"sig"}]},
        aud : '44k8h29dk575da6qbt3p0iv4u7', // should match the app client ID that was created in the Amazon Cognito user pool
        iss : 'https://cognito-idp.ap-southeast-2.amazonaws.com/ap-southeast-2_Z3B83Gjge',
        apiKeyPrefix: 'ApiKey',
        userPool: 'ap-southeast-2_Z3B83Gjge',
        idPool: 'ap-southeast-2:7dd386a0-abfa-4be4-89ba-8087d96b6c25',
        domain: 'evrimaconnect-dev.auth.ap-southeast-2.amazoncognito.com',
        redirectSignIn: 'https://connectdev.evrima.com.au',
        redirectSignOut: 'https://connectdev.evrima.com.au',
    },
    mui: {
        xGridKey: 'f0b4c9163bd7542a8021c6fee2f3e3fbT1JERVI6MjcyMDIsRVhQSVJZPTE2NTgwMTkwNjcwMDAsS0VZVkVSU0lPTj0x'
    },
    intercom : {
        baseUrl: 'https://api-iam.au.intercom.io',
        appId: 'bwt6qo4l',
        enabled: false
    },
    permissions: {
        transparent: true // if false, a badge saying permission required will show where components are blocked by incorrect permissions
    },
    help: {
        link: 'https://evrima.com/contact-us/?enquiry_type=Evrima%20Connect%20Support'
    },
    docApi: 'https://f3zvhp1i6g.execute-api.ap-southeast-2.amazonaws.com/Prod/agreements'
};

export default config;

// aws: {
//     baseUrl : 'https://n9z23uy4ml.execute-api.ap-southeast-2.amazonaws.com/Prod',
//         xApiKey : 'tlzlNq98br5d357LugD8j1noT7RVHMay7sByU7IE'
// },
